import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Response } from '@angular/http';
import { Injectable, Injector } from '@angular/core';

@Injectable()
export class LocalJsonService {

  constructor(
    private injector: Injector) {}

  // tslint:disable-next-line: align
  public getLocalJson(fileName) {
    return this.injector.get(HttpClient).get(`assets/json/${fileName}`);
  }
}
